import React, { Component } from 'react';

import './ViewQuote.css';
import ualogo from './utility-aid.png';
import { Apibase } from './Apibase';
import { PDFExport } from '@progress/kendo-react-pdf';

import fetch from './refreshTokenFetch';

class PageTemplate extends React.Component {
    render() {
        return (
            <div
                className="col-12"
                style={{ position: 'absolute', top: '10px' }}
            >
                <div className="row">
                    <div className="col-8">
                        <img
                            className="quotelogo"
                            src={ualogo}
                            alt="utility aid logo"
                        ></img>
                    </div>

                    <div
                        className="col-4  mt-3 small  "
                        style={{ fontSize: '10px' }}
                    >
                        <div className="row ml-1">
                            {this.props.customerSummary[0]
                                ? this.props.customerSummary[0].CustomerName
                                : ''}
                        </div>

                        <div className="row ml-1">
                            {this.props.customerSummary[0]
                                ? this.props.customerSummary[0].MainAddressLine1
                                : ''}
                        </div>
                        <div className="row ml-1">
                            {this.props.customerSummary[0]
                                ? this.props.customerSummary[0].MainAddressLine2
                                : ''}
                        </div>
                        <div className="row ml-1">
                            {this.props.customerSummary[0]
                                ? this.props.customerSummary[0].MainAddressLine3
                                : ''}
                        </div>
                        <div className="row ml-1">
                            {this.props.customerSummary[0]
                                ? this.props.customerSummary[0].MainAddressLine4
                                : ''}
                        </div>
                        <div className="row ml-1">
                            {this.props.customerSummary[0]
                                ? this.props.customerSummary[0].MainPostcode
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ViewCompletedQuote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            QuoteID: this.props.match.params.id,
            currentAnnualCost: 0,
            metersSummary: [],
            pricesSummary: [],
            recommendedOffer: [{ TotalAnnualCost: 0, CostDiff: 0 }],
            peopleSummary: [],
            customerSummary: [],
            includeRecomended: true,
        };
    }

    componentDidMount() {
        this.getQuoteDetail(this.props.match.params.id);
    }

    exportPDF = () => {
        this.quote.save();
    };

    currentdate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        return today;
    };

    formatLeadingzeros = (num, len) => {
        var formatedval = num.toString();
        for (var i = num.toString().length; i < len; i++) {
            formatedval = '0' + formatedval;
        }

        return formatedval;
    };

    getQuoteDetail(quoteID) {
        fetch(Apibase + '/api/v1/quotes/custQuote/' + quoteID, {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    var pricesGrouped = {};
                    var PricesDetail = result.pricesDetail;
                    for (var i = 0; i < PricesDetail.length; i++) {
                        if (!pricesGrouped[PricesDetail[i].PriceName]) {
                            pricesGrouped[PricesDetail[i].PriceName] = [];
                        }
                        pricesGrouped[PricesDetail[i].PriceName].push(
                            PricesDetail[i]
                        );
                    }
                    //console.log(result);
                    this.setState({
                        metersSummary: result.meters,
                        pricesSummary: result.prices,
                        currentAnnualCost:
                            result.prices.length > 0
                                ? result.prices[0].TotalCurrentAnnualCost
                                : 0,
                        peopleSummary: result.people,
                        customerSummary: result.customer,
                        PricesGrouped: pricesGrouped,
                    });
                    //console.log(result);

                    var RecommendedPrices = result.prices.filter(function (el) {
                        return el.Recommend === 1;
                    });

                    if (RecommendedPrices.length === 0) {
                        RecommendedPrices = [
                            {
                                CostDiff: 0,
                                TotalAnnualCost: 0,
                                TotalCurrentAnnualCost: 0,
                            },
                        ];
                    }

                    this.setState({ recommendedOffer: RecommendedPrices });
                    //console.log(RecommendedPrices);
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //console.log(error);
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    }

    render() {
        return (
            <>
                <button onClick={this.exportPDF}>download</button>
                <div className="container-fluid viewquote">
                    <PDFExport
                        forcePageBreak=".page-break"
                        pageTemplate={(props) => (
                            <PageTemplate
                                {...props}
                                customerSummary={this.state.customerSummary}
                            />
                        )}
                        scale={0.74}
                        paperSize={'A4'}
                        fileName="quote.pdf"
                        ref={(r) => (this.quote = r)}
                        landscape={true}
                    >
                        <div className="quote-summary row ml-2 mr-2 mt-5 pt-5   ">
                            <div className="col-7 p-1">
                                <p
                                    style={{
                                        paddingLeft: '3%',
                                        backgroundColor: '#0093C1',
                                        color: 'white',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Quotation Summary
                                </p>
                            </div>
                            <div className="col-5 p-1">
                                <p
                                    style={{
                                        paddingLeft: '3%',
                                        backgroundColor: '#0093C1',
                                        color: 'white',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Annual Consumption
                                </p>
                            </div>
                        </div>

                        <div className="quote-summary row ml-2 mr-2   ">
                            <div className="col-7 p-1">
                                <table
                                    style={{ fontSize: '9px' }}
                                    className="table table-bordered small table-sm"
                                >
                                    <thead>
                                        <tr>
                                            <th>Energy Type</th>
                                            <th>Meter Location</th>
                                            <th>Meter Type</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.metersSummary.map(
                                            (item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {item.MeterType}
                                                        </td>
                                                        <td>
                                                            See Attached
                                                            Quotation
                                                        </td>
                                                        <td>
                                                            {item.MeterType ===
                                                            'Electricity'
                                                                ? 'Electricity : ' +
                                                                  this.formatLeadingzeros(
                                                                      item.ProfileClassInt,
                                                                      2
                                                                  ) +
                                                                  ' Profile'
                                                                : 'Gas Meter'}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.NumberOfMeters
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-5 p-1">
                                <table
                                    style={{ fontSize: '9px' }}
                                    className="table table-bordered small table-sm"
                                >
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Night</th>
                                            <th>Eve/Wknd</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.metersSummary.map(
                                            (item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {item.TotalDayAQ.toLocaleString(
                                                                'en'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.TotalNightAQ.toLocaleString(
                                                                'en'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.TotalOtherAQ.toLocaleString(
                                                                'en'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.TotalAQ.toLocaleString(
                                                                'en'
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* <div className="row ml-2 mr-2">
                            <div className="col-12 pl-3 pr-3">
                                <p
                                    style={{
                                        fontSize: '9px',
                                        color: 'grey',
                                    }}
                                >
                                    All quotations are based on the last 12 months consumption data as held by the Industry.  It is the customers responsibility to check all site, meter and consumption data for accuracy and completeness raising any discrepancies prior to acceptance. 

Any quotations and/or proposals given by Utility Aid and/or the Utility Supplier to the Customer shall contain indicative rates which may not be guaranteed. Such rates shall only be confirmed following receipt of lock confirmation from the Utility Supplier. All quotations and/or proposals given by Utility Aid and/or the Utility Supplier shall only be valid for the period specifically stated when the quotation and/or proposals is offered.  
 
Utility Aid shall only receive a Commission from the Utility Supplier when Utility Aid successfully brokers a Utility Contract. The Commission payable to Utility Aid shall be based upon a fixed rate uplift contained within the rates proposed and will be payable to Utility Aid by the Supplier.
 
It is your responsibility to send a termination notice to your existing Supplier, at the point of transfer there is to be no debt on your account so that the transfer can move ahead smoothly. Utility Aid cannot be held responsible for any costs incurred due to delayed start dates. Should you receive notification of objection from your Supplier you must notify Utility Aid immediately.
 
The prices are subject to credit approval by the Supplier.
 
Please note prices are subject to Government taxations where applicable.

                                </p>
                            </div>
                        </div> */}

                        <div className="quote-summary row ml-2 mr-2 mt-2   ">
                            <div className="col-6 p-1">
                                <div className="row">
                                    <div className="col-12">
                                        <p
                                            style={{
                                                paddingLeft: '3%',
                                                backgroundColor: '#0093C1',
                                                color: 'white',
                                                marginBottom: '0px',
                                            }}
                                        >
                                            Supplier Offers
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div
                                        style={{ fontSize: '10px' }}
                                        className="col-8 "
                                    >
                                        <div className="pl-2 font-weight-bold">
                                            Current Estimated Annual Spend
                                        </div>
                                    </div>
                                    <div
                                        style={{ fontSize: '10px' }}
                                        className="col-4 "
                                    >
                                        <div className="pl-2 ">
                                            {this.state.currentAnnualCost
                                                ? this.state.currentAnnualCost.toLocaleString(
                                                      'en-UK',
                                                      {
                                                          style: 'currency',
                                                          currency: 'GBP',
                                                      }
                                                  )
                                                : 'Not Obtained'}
                                        </div>
                                    </div>
                                </div>
                                {/* /---------------------------------------------/ */}
                                {this.state.pricesSummary.map((item, key) => {
                                    if (key > 4) return null
                                    return (
                                        <div
                                            key={key}
                                            className="row mt-4 border m-1"
                                        >
                                            <div
                                                style={{
                                                    fontSize: '10px',
                                                }}
                                                className="col-8 p-0"
                                            >
                                                <div className="pl-2 font-weight-bold">
                                                    {item.PriceName.indexOf(
                                                        '~,~'
                                                    ) > 0
                                                        ? item.PriceName.split(
                                                              '~,~'
                                                          )[0] +
                                                          ' ' +
                                                          item.PriceName.split(
                                                              '~,~'
                                                          )[1] +
                                                          ' ' +
                                                          (item.PriceName.split(
                                                              '~,~'
                                                          )[2] !==
                                                          ' plan type:null'
                                                              ? item.PriceName.split(
                                                                    '~,~'
                                                                )[2]
                                                              : '')
                                                        : item.PriceName.substr(
                                                              0,
                                                              item.PriceName.indexOf(
                                                                  'TimeStamp'
                                                              ) - 2
                                                          )}
                                                </div>
                                                <div className="pl-2 font-weight-bold">
                                                    Estimated Annual &nbsp;
                                                    {item.CostDiff < 0
                                                        ? 'Increase'
                                                        : 'Saving'}
                                                </div>
                                                <div className="pl-2 font-weight-bold">
                                                    Estimated Percentage &nbsp;
                                                    {item.CostDiff < 0
                                                        ? 'Increase'
                                                        : 'Saving'}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: '10px',
                                                }}
                                                className="col-4 "
                                            >
                                                <div
                                                    className={
                                                        item.CostDiff < 0
                                                            ? 'pl-3 text-danger'
                                                            : 'pl-3 text-success'
                                                    }
                                                >
                                                    {item.TotalAnnualCost.toLocaleString(
                                                        'en-UK',
                                                        {
                                                            style: 'currency',
                                                            currency: 'GBP',
                                                        }
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        item.CostDiff < 0
                                                            ? 'pl-3 text-danger'
                                                            : 'pl-3 text-success'
                                                    }
                                                >
                                                    {item.TotalCurrentAnnualCost
                                                        ? Math.abs(
                                                              item.CostDiff
                                                          ).toLocaleString(
                                                              'en-UK',
                                                              {
                                                                  style: 'currency',
                                                                  currency:
                                                                      'GBP',
                                                              }
                                                          )
                                                        : ' '}
                                                </div>
                                                <div
                                                    className={
                                                        item.CostDiff < 0
                                                            ? 'pl-3 text-danger'
                                                            : 'pl-3 text-success'
                                                    }
                                                >
                                                    {item.TotalCurrentAnnualCost
                                                        ? (
                                                              (item.CostDiff /
                                                                  item.TotalCurrentAnnualCost) *
                                                              100
                                                          ).toFixed(2) + '%'
                                                        : ' '}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {this.state.pricesSummary.length > 5 ?
                                <div className="col-6 p-1">
                                    <div className="row" style={{ marginBottom: '47px' }}>
                                        <div className="col-12">
                                            <p
                                                style={{
                                                    paddingLeft: '3%',
                                                    backgroundColor: '#0093C1',
                                                    color: 'white',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Supplier Offers
                                            </p>
                                        </div>
                                    </div>
                                    {this.state.pricesSummary.map((item, key) => {
                                        if (key > 9 || key < 5) return null
                                        return (
                                            <div
                                                key={key}
                                                className="row mt-4 border m-1"
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '10px',
                                                    }}
                                                    className="col-8 p-0"
                                                >
                                                    <div className="pl-2 font-weight-bold">
                                                        {item.PriceName.indexOf(
                                                            '~,~'
                                                        ) > 0
                                                            ? item.PriceName.split(
                                                                '~,~'
                                                            )[0] +
                                                            ' ' +
                                                            item.PriceName.split(
                                                                '~,~'
                                                            )[1] +
                                                            ' ' +
                                                            (item.PriceName.split(
                                                                '~,~'
                                                            )[2] !==
                                                                ' plan type:null'
                                                                ? item.PriceName.split(
                                                                    '~,~'
                                                                )[2]
                                                                : '')
                                                            : item.PriceName.substr(
                                                                0,
                                                                item.PriceName.indexOf(
                                                                    'TimeStamp'
                                                                ) - 2
                                                            )}
                                                    </div>
                                                    <div className="pl-2 font-weight-bold">
                                                        Estimated Annual &nbsp;
                                                        {item.CostDiff < 0
                                                            ? 'Increase'
                                                            : 'Saving'}
                                                    </div>
                                                    <div className="pl-2 font-weight-bold">
                                                        Estimated Percentage &nbsp;
                                                        {item.CostDiff < 0
                                                            ? 'Increase'
                                                            : 'Saving'}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: '10px',
                                                    }}
                                                    className="col-4 "
                                                >
                                                    <div
                                                        className={
                                                            item.CostDiff < 0
                                                                ? 'pl-3 text-danger'
                                                                : 'pl-3 text-success'
                                                        }
                                                    >
                                                        {item.TotalAnnualCost.toLocaleString(
                                                            'en-UK',
                                                            {
                                                                style: 'currency',
                                                                currency: 'GBP',
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            item.CostDiff < 0
                                                                ? 'pl-3 text-danger'
                                                                : 'pl-3 text-success'
                                                        }
                                                    >
                                                        {item.TotalCurrentAnnualCost
                                                            ? Math.abs(
                                                                item.CostDiff
                                                            ).toLocaleString(
                                                                'en-UK',
                                                                {
                                                                    style: 'currency',
                                                                    currency:
                                                                        'GBP',
                                                                }
                                                            )
                                                            : ' '}
                                                    </div>
                                                    <div
                                                        className={
                                                            item.CostDiff < 0
                                                                ? 'pl-3 text-danger'
                                                                : 'pl-3 text-success'
                                                        }
                                                    >
                                                        {item.TotalCurrentAnnualCost
                                                            ? (
                                                                (item.CostDiff /
                                                                    item.TotalCurrentAnnualCost) *
                                                                100
                                                            ).toFixed(2) + '%'
                                                            : ' '}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                : null}
                            {/* <div className=" col-5 offset-1 pt-1">
                                <div className="row">
                                    <div className="text-center  col-6 p-0">
                                        <p
                                            className="border border-dark text-center"
                                            style={{
                                                backgroundColor: '#0093C1',
                                                color: 'white',
                                                marginBottom: '0px',
                                            }}
                                        >
                                            Total Current Spend
                                        </p>
                                        <div className="border border-dark">
                                            {this.state.currentAnnualCost
                                                ? this.state.currentAnnualCost.toLocaleString(
                                                      'en-UK',
                                                      {
                                                          style: 'currency',
                                                          currency: 'GBP',
                                                      }
                                                  )
                                                : 'Not Obtained'}
                                        </div>
                                        <div className="border border-dark">
                                            <br></br>
                                        </div>
                                        <div className="border border-dark">
                                            <br></br>
                                        </div>
                                    </div>

                                    <div className="text-center  col-6 p-0 pr-1">
                                        <p
                                            className="border border-dark text-center"
                                            style={{
                                                backgroundColor: '#0093C1',
                                                color: 'white',
                                                marginBottom: '0px',
                                            }}
                                        >
                                            {this.state.recommendedOffer[0]
                                                .TotalAnnualCost ? (
                                                'Recommended offer'
                                            ) : (
                                                <br></br>
                                            )}
                                        </p>
                                        <div
                                            className={
                                                this.state.recommendedOffer[0]
                                                    .CostDiff < 0
                                                    ? 'border border-dark text-danger '
                                                    : 'border border-dark text-success '
                                            }
                                        >
                                            {this.state.recommendedOffer[0]
                                                .TotalAnnualCost ? (
                                                this.state.recommendedOffer[0].TotalAnnualCost.toLocaleString(
                                                    'en-UK',
                                                    {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                    }
                                                )
                                            ) : (
                                                <br></br>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                this.state.recommendedOffer[0]
                                                    .CostDiff < 0
                                                    ? 'border border-dark text-danger '
                                                    : 'border border-dark text-success '
                                            }
                                        >
                                            {this.state.recommendedOffer[0]
                                                .TotalAnnualCost ? (
                                                this.state.recommendedOffer[0]
                                                    .TotalCurrentAnnualCost ? (
                                                    Math.abs(
                                                        this.state
                                                            .recommendedOffer[0]
                                                            .CostDiff
                                                    ).toLocaleString('en-UK', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                    })
                                                ) : (
                                                    <br></br>
                                                )
                                            ) : (
                                                <br></br>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                this.state.recommendedOffer[0]
                                                    .CostDiff < 0
                                                    ? 'border border-dark text-danger '
                                                    : 'border border-dark text-success '
                                            }
                                        >
                                            {this.state.recommendedOffer[0]
                                                .TotalAnnualCost ? (
                                                this.state.recommendedOffer[0]
                                                    .TotalCurrentAnnualCost ? (
                                                    (
                                                        (this.state
                                                            .recommendedOffer[0]
                                                            .CostDiff /
                                                            this.state
                                                                .recommendedOffer[0]
                                                                .TotalCurrentAnnualCost) *
                                                        100
                                                    ).toFixed(2) + '%'
                                                ) : (
                                                    <br></br>
                                                )
                                            ) : (
                                                <br></br>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row pt-3"
                                    style={{ fontSize: '0.7rem' }}
                                >
                                    <div className="text-center   col-4 p-0">
                                        <p
                                            className="border border-dark text-center"
                                            style={{
                                                marginBottom: '0px',
                                                fontWeight: 'bold',
                                                height: '40px'
                                            }}
                                        >
                                            Involvement in your quotation
                                        </p>
                                        <div className="border border-dark">
                                            Presented By
                                        </div>
                                        <div className="border border-dark">
                                            Prepared By
                                        </div>
                                        <div className="border border-dark">
                                            Info Obtained By
                                        </div>
                                    </div>
                                    <div className="text-center  col-4 p-0">
                                        <p
                                            className="border border-dark text-center"
                                            style={{
                                                marginBottom: '0px',
                                                fontWeight: 'bold',
                                                height: '40px'
                                            }}
                                        >
                                            Name of Utility Aid staff member
                                        </p>
                                        <div className="border border-dark">
                                            {this.state.peopleSummary[0]
                                                ? this.state.peopleSummary[0]
                                                      .SpecialistFullName
                                                : ''}
                                        </div>
                                        <div className="border border-dark">
                                            {this.state.peopleSummary[0]
                                                ? this.state.peopleSummary[0]
                                                      .PricingFullName
                                                : ''}
                                        </div>
                                        <div className="border border-dark">
                                            Marc Albanese
                                        </div>
                                    </div>

                                    <div className="text-center  col-4 p-0 pr-1">
                                        <p
                                            className="border border-dark text-center"
                                            style={{
                                                marginBottom: '0px',
                                                fontWeight: 'bold',
                                                height: '40px'
                                            }}
                                        >
                                            Position in Utility Aid
                                        </p>
                                        <div className="border border-dark ">
                                            Energy Specialist
                                        </div>
                                        <div className="border border-dark ">
                                            {this.state.peopleSummary[0]
                                                ? this.state.peopleSummary[0]
                                                      .PricingFullName ===
                                                  this.state.peopleSummary[0]
                                                      .SpecialistFullName
                                                    ? 'Energy Specialist'
                                                    : 'Procurement'
                                                : ''}
                                        </div>
                                        <div className="border border-dark ">
                                            Info Team
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row pt-3"
                                    style={{ fontSize: '0.6rem' }}
                                > */}
                                    {/* <div className="col-12 p-0">
                                        <div
                                            className="border border-dark text-left"
                                            style={{
                                                marginBottom: '0px',

                                                padding: '5px',
                                            }}
                                        >
                                            {this.state.peopleSummary[0]
                                                ? 'Please sign this section and email this acceptance to ' +
                                                  this.state.peopleSummary[0]
                                                      .SpecialistFullName
                                                : 'Please sign this section and email this acceptance to'}
                                            <br></br>
                                            <br></br>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                {this.state.customerSummary[0]
                                                    ? 'I confirm that I am an authorised representative of ' +
                                                      this.state
                                                          .customerSummary[0]
                                                          .CustomerName +
                                                      ', and I wish to accept this contract offer for Gas and Electricity Supplies through Utility Aid and the successful supplier, for the contracted term.'
                                                    : 'I wish to accept this contract offer for Gas and Electricity Supplies through Utility Aid and the successful supplier, for the contracted term.'}
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                {this.state.customerSummary[0]
                                                    ? 'As authorised signatory for ' +
                                                      this.state
                                                          .customerSummary[0]
                                                          .CustomerName +
                                                      ', I hereby also accept the Utility Aid Terms and Conditions sent separately. I also authorise Utility Aid to sign the contractual documentation with the successful supplier.'
                                                    : 'I hereby also accept the Utility Aid Terms and Conditions sent separately. I also authorise Utility Aid to sign the contractual documentation with the successful supplier.'}
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Signature:
                                            </p>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Position:
                                            </p>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Print Name:
                                            </p>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Date:
                                            </p>
                                        </div>
                                    </div> */}
                                {/* </div> */}
                                {/* <div
                                    className="row pt-3"
                                    style={{ fontSize: '0.6rem' }}
                                >
                                    <div className="col-12 p-0">
                                        <div
                                            className="border border-dark text-left"
                                            style={{
                                                marginBottom: '0px',

                                                padding: '5px',
                                            }}
                                        >
                                            Signed:
                                            <br></br>
                                            <br></br>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                    fontFamily:
                                                        'Dancing Script, cursive',
                                                    fontSize: '1.4rem',
                                                }}
                                            >
                                                {this.state.peopleSummary[0]
                                                    ? this.state
                                                          .peopleSummary[0]
                                                          .SpecialistFullName
                                                    : ''}
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Title: Energy Specialist
                                            </p>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Date: {this.currentdate()}
                                            </p>
                                            <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Phone: 0808 178 8170
                                            </p>
                                            <br></br>
                                            {/* <p
                                                className=" text-left"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                Email:
                                                Apatterson@utility-aid.co.uk
                                            </p> */}
                                        {/* </div>
                                    </div>
                                </div> 
                            </div>*/}
                        </div>
                        {/* {console.log(this.state.PricesDetail)} */}
                        <LineData PricesDetail={this.state.PricesGrouped} />
                        <Disclaimer />
                    </PDFExport>
                </div>
            </>
        );
    }
}

class LineData extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props);
        this.state = {};
    }

    componentDidUpdate() {
        //this.setState({ GroupedPrices: this.props.PricesDetail });
        // console.log(this.props.PricesDetail);
        //console.log(Object.keys(this.props.PricesDetail).length);
    }

    createtables() {
        var valToReturn = [];
        const isHH =
            Object.keys(this.props.PricesDetail)[0].indexOf('Fuel:HH') > 0
                ? true
                : false;

        //console.log(this.props.PricesDetail[1]);
        for (var z = 0; z < Object.keys(this.props.PricesDetail).length; z++) {
            //console.log(this.props.PricesDetail);
            var PriceName;

            if (Object.keys(this.props.PricesDetail)[z].indexOf('~,~') > 0) {
                PriceName =
                    Object.keys(this.props.PricesDetail)[z].split('~,~')[0] +
                    ' ' +
                    Object.keys(this.props.PricesDetail)[z].split('~,~')[1] +
                    ' ' +
                    (Object.keys(this.props.PricesDetail)[z].split('~,~')[2] !==
                    ' plan type:null'
                        ? Object.keys(this.props.PricesDetail)[z].split(
                              '~,~'
                          )[2]
                        : '');
            } else {
                var postimestamp = Object.keys(this.props.PricesDetail)[
                    z
                ].indexOf('TimeStamp');
                PriceName = Object.keys(this.props.PricesDetail)[z].substr(
                    0,
                    postimestamp - 2
                );
            }
            valToReturn.push(
                <div key={z}>
                    <div className="col-12 p-1 ">
                        <p
                            className="border border-dark text-center"
                            style={{
                                paddingLeft: '3%',
                                backgroundColor: '#0093C1',
                                color: 'white',
                                marginBottom: '0px',
                            }}
                        >
                            {PriceName}
                        </p>
                    </div>
                    <div className="table-responsive">
                        <table
                            id=""
                            className="table  table-bordered table-striped small table-sm p-0 m-0 "
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: '9%' }}>Meter Number</th>
                                    <th style={{ width: '7%' }}>Address</th>
                                    <th style={{ width: '7%' }}>Start Date</th>
                                    <th style={{ width: '5%' }}>Day Rate</th>
                                    <th style={{ width: '5%' }}>Night Rate</th>
                                    <th style={{ width: '5%' }}>Other Rate</th>
                                    <th style={{ width: '5%' }}>FITS</th>
                                    <th style={{ width: '5%' }}>
                                        Standing Charge
                                    </th>
                                    <th style={{ width: '7%' }}>
                                        Standing Charge Frequency
                                    </th>
                                    {/* {isHH ? (
                                        <th style={{ width: '5%' }}>
                                            KVA Rate
                                        </th>
                                    ) : null} */}
                                    {isHH ? (
                                        <th style={{ width: '3%' }}>
                                            KVA
                                        </th>
                                    ) : null}
                                    {isHH ? (
                                        <th style={{ width: '6%' }}>
                                            Annual KVA Cost
                                        </th>
                                    ) : null}
                                    <th style={{ width: '6%' }}>Day AQ</th>
                                    <th style={{ width: '6%' }}>Night AQ</th>
                                    <th style={{ width: '6%' }}>Other AQ</th>
                                    <th style={{ width: '6%' }}>Total AQ</th>
                                    <th style={{overflowWrap: 'break-word', width: '9%'}}>Estimated Annual Spend</th>
                                </tr>
                            </thead>
                            <tbody className="" id="">
                                {this.props.PricesDetail[
                                    Object.keys(this.props.PricesDetail)[z]
                                ].map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{overflowWrap: 'break-word', width: '9%'}}>{item.MeterNumber}</td>
                                            <td style={{ overflowWrap: 'break-word', width: '7%', fontSize: '9px' , padding: '1px 3px' }}>
                                                {item.FullSiteAddress
                                                    ? item.FullSiteAddress.substr(
                                                          0,
                                                          30
                                                      )
                                                    : ' '}
                                            </td>
                                            <td style={{ width: '7%' }}>
                                                {item.StartDate}
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {item.DayRate
                                                    ? parseFloat(
                                                          item.DayRate
                                                      ).toFixed(3)
                                                    : 0}
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {item.NightRate
                                                    ? parseFloat(
                                                          item.NightRate
                                                      ).toFixed(3)
                                                    : 0}
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {item.OtherRate
                                                    ? parseFloat(
                                                          item.OtherRate
                                                      ).toFixed(3)
                                                    : 0}
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {item.FITS ? item.FITS : 0}
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {item.StandingCharge
                                                    ? parseFloat(
                                                          item.StandingCharge
                                                      ).toFixed(3)
                                                    : 0}
                                            </td>
                                            <td style={{ width: '7%' }}>
                                                {item.StandingChargeFrequency}
                                            </td>
                                            {/* {isHH ? (
                                                <td style={{ width: '5%' }}>
                                                    {Math.abs(
                                                        (item.CostPerKVA
                                                            ? item.CostPerKVA
                                                            : 0)
                                                    ).toLocaleString('en-UK', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                    })}
                                                </td>
                                            ) : null} */}
                                            {isHH ? (
                                                <td style={{ width: '6%' }}>
                                                    {Math.abs(
                                                        (item.KVAPerMonth
                                                            ? item.KVAPerMonth
                                                            : 0)
                                                    )}
                                                </td>
                                            ) : null}
                                            {isHH ? (
                                                <td style={{ width: '8%' }}>
                                                    {Math.abs(
                                                        (item.CostPerKVA
                                                            ? item.CostPerKVA
                                                            : 0) *
                                                            (item.KVAPerMonth
                                                                ? item.KVAPerMonth
                                                                : 0)
                                                    ).toLocaleString('en-UK', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                    })}
                                                </td>
                                            ) : null}
                                            <td style={{ width: '6%', overflowWrap: 'break-word' }}>
                                                {(item.DayAQ).toLocaleString()}
                                            </td>
                                            <td style={{ width: '6%', overflowWrap: 'break-word' }}>
                                                {(item.NightAQ).toLocaleString()}
                                            </td>
                                            <td style={{ width: '6%', overflowWrap: 'break-word' }}>
                                                {(item.OtherAQ).toLocaleString()}
                                            </td>
                                            <td style={{ width: '6%', overflowWrap: 'break-word' }}>
                                                {(item.DayAQ + item.NightAQ + item.OtherAQ).toLocaleString()}
                                            </td>
                                            <td style={{overflowWrap: 'break-word', width: '9%'}}>
                                                {Math.abs(
                                                    item.AnnualCost
                                                ).toLocaleString('en-UK', {
                                                    style: 'currency',
                                                    currency: 'GBP',
                                                })}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        //console.log(numrows);
        return valToReturn;
    }

    render() {
        return (
            <div className="col-12 p-0">
                <div className="page-break"></div>
                <div className="container-fluid mt-5 pt-5">
                    <div className="mb-3">
                        {this.props.PricesDetail
                            ? Object.keys(this.props.PricesDetail).length !== 0
                                ? this.createtables()
                                : null
                            : null}

                        {/* <button
              onClick={() => this.createtables}
              type="button"
              className="btn btn-success"
            >
              Select Prices To Include
            </button> */}
                    </div>
                </div>
            </div>
        );
    }
}


class Disclaimer extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props);
        this.state = {};
    }

    render() {
        return (
            <div className="col-12 p-0">
                <div className="page-break">
                <div className="container-fluid mt-5 pt-5">
                    <div className="mb-3">
            <div className="disclaimer" style={{fontFamily: 'sans-serif'}}>
<p><strong><span>Disclaimer</span></strong><span >, Please note; Utility Aid Limited <strong>(UAL</strong>) and the <strong>Client</strong>;&nbsp;</span></p>
<ul type="disc" style={{listStyleType: 'disc'}} className='bullet'>
    <li ><span >This report and any communications in relation to the contents of this report are private, confidential, and intended for the client only.</span></li>
    <li ><span >Prices are provided in accordance with UAL standard terms and conditions and do not include VAT.</span></li>
    <li ><span >All prices include UAL commissions, unless agreed otherwise.</span></li>
    <li ><span >Any supply contract entered into is a direct contract between the client and the selected energy supplier, and as such, the client will be bound by the terms and conditions provided by the energy supplier. It is the client&apos;s responsibility to ensure it has read, understood, and accepted the energy supplier&rsquo;s terms and conditions before accepting any contract.</span></li>
    <li ><span >All offers made by the suppliers are subject to their terms and conditions, availability, and credit status. The suppliers reserve the right to vary or withdraw any offer at any time without prior notice.</span></li>
    <li ><span >UAL is not liable for any damages or losses of any kind, whether foreseeable or not (including, but without limitation, financial loss, damages for loss in business projects, loss of profits, or other consequential losses) arising in contract, tort, or otherwise as a direct or indirect result from action taken or decisions made as a result of reliance on material, in whole or in part, contained in the report. Nothing contained in the report constitutes financial, legal, tax, or other advice, nor should any investment or any other decision be made solely based on the report content.</span></li>
    <li ><span >Please note your supplier may require you to install a smart meter at the commence date of your contract, some suppliers can apply charges if this clause is not met. Please refer to the suppliers terms and conditions at point of agreeing a contract with your chosen supplier.</span></li>
    <li style={{flexWrap: 'wrap'}} ><span >It is the responsibility of the client to;&nbsp;</span>
        <ul className="nestedBullet">
            <li ><span >Ensure all the tendered properties are the responsibility of the client. If any sites are not the responsibility of the client, these should be immediately identified to UAL so that they can be removed from any future supply contracts.</span></li>
            <li ><span >Ensure all sites are eligible to transfer to a new supplier, including ensuring that termination notices have been issued in accordance with the incumbent energy supplier&apos;s terms and conditions and that any debt greater than 30 days old has been cleared.&nbsp;</span></li>
            <li ><span >Aware that any objections to the transfer could result in out-of-contract rates being applied by the incumbent supplier.</span></li>
            <li ><span>They understands all quotations are based on industry held data for the previous 12 months and it is the clients responsibility to check all site, meter, and consumption data for accuracy and completeness, raising any discrepancies prior to acceptance.</span></li>
            <li ><span>There are elements of cost and rates that could be PT (pass-through) within all supplier contracts that are potentially subject to change either before, during, or after the life of the contract. These charges are referred to as non-energy costs and include, but are not limited to metering, transportation, distribution, energy market reform, climate change levies (CCL), and any other third-party or pass-through charges. UAL has no responsibility over these changes should they occur and therefore cannot be held liable.</span></li>
        </ul>
    </li>
</ul>
                    </div>
                </div>
                </div>
                </div>
                </div>
        )
    }
}

export default ViewCompletedQuote;
